import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/apps/blog/src/components/PageLayout/PageLayout.tsx";
import { BlogList, Container, HomePageHeader, PageOffset } from 'components';
import { graphql } from 'gatsby';
export const homePageQuery = graphql`
    query homePageQuery {
        allMdx(
            sort: { fields: [frontmatter___sort], order: DESC }
            filter: { frontmatter: { published: { eq: true }, kind: { eq: "Blog" } } }
            limit: 3
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                        preview
                        description
                        published
                        slug
                        images {
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        width: 3000
                                        quality: 80
                                        placeholder: BLURRED
                                    )
                                }
                            }
                            isCover
                        }
                    }
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  homePageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HomePageHeader mdxType="HomePageHeader" />
    <h2>{`Latest in Blog`}</h2>
    <BlogList data={props.data.allMdx.edges} showLink mdxType="BlogList" />
    <br />
    <PageOffset mdxType="PageOffset" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      